import React from 'react';
import './Routes.css';


function Basket() {
   return (
      <div className='basket'>
         basket
      </div>
   );
}

export default Basket;
